import React, { useState } from "react";
import "./App.css"; // Add your CSS file for styling
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import image from "./assets/7.PNG";
import NavbarNew from "./navbar";

const App = () => {
  const [counton, setCounton] = useState(false);
  const [counter, setCounter] = useState({
    counter1: 50,
    counter2: 100,
    counter3: 200,
    counter4: 300,
  });
  return (
    <>
      <div className="index-page">
        <NavbarNew />
        <Header />
        <main className="main">
          <HeroSection />

          <section id="details" class="details section">
            <div class="container section-title" data-aos="fade-up">
              <h2>About US</h2>
              <div>
                <span class="description-title">
                  SKANS SCHOOLS OF ACCOUNTANCY
                </span>
              </div>
            </div>

            <div class="container">
              <div class="row gy-4 align-items-center features-item">
                <div
                  class="col-md-5 d-flex align-items-center"
                  data-aos="zoom-out"
                  data-aos-delay="100"
                >
                  <img
                    src="	https://skans.pk/static/media/F8Campus%20-%20Copy.2ccb14b50db77a8e76b2.jpg"
                    class="img-fluid rounded-4"
                    alt=""
                  />
                </div>
                <div class="col-md-7" data-aos="fade-up" data-aos-delay="100">
                  {/* <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h3> */}
                  <p class="fst-italic fs-5">
                    SKANS is the acronym of an institution, which stands for
                    Skills, Knowledge and Application Nurtured through
                    Student-managed participative learning approach. It aims to
                    be one of the leading business schools in the field of
                    Professional Accountancy Education, catering to the students
                    needs in a proactive manner. It has developed an expertise
                    in the realm of CA (Pak), ACCA (UK), CIMA (UK), CAT (UK),
                    CISA(USA).
                  </p>
                  {/* <ul>
        <li><i class="bi bi-check"></i><span> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></li>
        <li><i class="bi bi-check"></i> <span>Duis aute irure dolor in reprehenderit in voluptate velit.</span></li>
        <li><i class="bi bi-check"></i> <span>Ullam est qui quos consequatur eos accusamus.</span></li>
      </ul> */}
                </div>
              </div>
            </div>
          </section>
          <ScrollTrigger
            onEnter={() => setCounton(true)}
            onExit={() => setCounton(false)}
          >
            <section id="stats" class="stats section light-background">
              <div class="container">
                <div class="row gy-4">
                  <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                    <i class="bi bi-emoji-smile"></i>
                    <div class="stats-item">
                      {counton && (
                        <CountUp
                          start={0}
                          end={counter.counter1}
                          duration={2}
                          delay={0}
                        />
                      )}
                      <p>Happy Students</p>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                    <i class="bi bi-building"></i>
                    <div class="stats-item">
                      {counton && (
                        <CountUp
                          start={0}
                          end={counter.counter2}
                          duration={2}
                          delay={0}
                        />
                      )}
                      <p>Campuses</p>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                    <i class="bi bi-people"></i>
                    <div class="stats-item">
                      {counton && (
                        <CountUp
                          start={0}
                          end={counter.counter3}
                          duration={2}
                          delay={0}
                        />
                      )}
                      <p>Experienced Faculty</p>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                    <i class="bi bi-people"></i>
                    <div class="stats-item">
                      {counton && (
                        <CountUp
                          start={0}
                          end={counter.counter4}
                          duration={2}
                          delay={0}
                        />
                      )}
                      <p>Enthusiastic Employers</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ScrollTrigger>

          <div className="container overflow-hidden">
            <img src={image}></img>
          </div>

          <div class="container-fluid my-3">
          <div className='my-5 pt-5 bg-lightgrey overflow-hidden' data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">
        <div className='container pb-2'>
          <div className='d-flex flex-wrap justify-content-between align-items-center '>
            <div className='d-flex justify-content-lg-start  justify-content-center align-items-center fw-bold col-lg-6 col-12 fw-bold '>
              {/* <img height={"50%"} src={Sectiontitle1}></img>&nbsp;&nbsp; */}
              <span className='fs-4 text-center'><span className='fs-3 fontcolordark fw-bold' style={{ fontFamily: "serif" }}>SKANS</span> HIGH ACHIEVERS</span>&nbsp; &nbsp;
              {/* <img height={"50%"} src={Sectiontitle2}></img> */}

            </div>
            <div className='col-lg-6 col-12 lh-lg text-justify'>
              "At SKANS School of Accountancy, we take immense pride in our accomplished achievers. With a proven track record of excellence, our graduates consistently secure top positions in professional accounting examinations. Our commitment to quality education and unwavering support from dedicated faculty ensures that every student becomes a high-achieving professional, ready to excel in the accounting world."
            </div>


          </div>
        </div>









        <div className=''>
          {/* <div class="card" style={{width: "18rem"}}>
  <img class="card-img-top" src="..." alt="Card image cap"/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div> */}

          {/* <MultiItemCarousel /> */}
            <div class="members text-center">
              <div class="Marquee">
                <div class="Marquee-content">
                  <div class="Marquee-tag">
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Emma</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. David</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. Liam</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sophia</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sadaf</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sadaf</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>
                 
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sadaf</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sadaf</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sadaf</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sadaf</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div> <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sadaf</h4>
                      <p>Batch 2024</p>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div class="members text-center">
              <div class="Marquee">
                <div class="Marquee-content2">
                  <div class="Marquee-tag">
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Emma</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. David</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. Liam</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sophia</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. Sadaf</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. Asad</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mrs. ALina</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. Ahmed</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. Awais</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. Usama</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. Junaid</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>
                  <div class="Marquee-tag">
                    {" "}
                    <div class="team-mem">
                      <img className="img11" src="https://static.vecteezy.com/system/resources/previews/015/409/989/non_2x/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.jpg" />
                      <h4>Mr. Haris</h4>
                      <p>Batch 2025</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
        </div>
      </div>

          </div>
          <section id="contact" class="contact section">
            <div class="container section-title" data-aos="fade-up">
              <h2>Contact</h2>
              <div>
                <span>Check Our</span>{" "}
                <span class="description-title">Contact</span>
              </div>
            </div>

            <div class="container" data-aos="fade" data-aos-delay="100">
              <div class="row gy-4">
                <div class="col-lg-4">
                  <div
                    class="info-item d-flex"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i class="bi bi-geo-alt flex-shrink-0"></i>
                    <div>
                      <h3>Address</h3>
                      <p>
                        P26R+524, Nazim-ud-din Rd, F-8 Markaz F 8 Markaz F-8,
                        Islamabad, Islamabad Capital Territory 44000
                      </p>
                    </div>
                  </div>

                  <div
                    class="info-item d-flex"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i class="bi bi-telephone flex-shrink-0"></i>
                    <div>
                      <h3>Call Us</h3>
                      <p>+92 3000000000</p>
                    </div>
                  </div>

                  <div
                    class="info-item d-flex"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-envelope flex-shrink-0"></i>
                    <div>
                      <h3>Email Us</h3>
                      <p>info@skans.pk</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-8">
                  <form
                    action="forms/contact.php"
                    method="post"
                    class="php-email-form"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div class="row gy-4">
                      <div class="col-md-6">
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          placeholder="Your Name"
                          required=""
                        />
                      </div>

                      <div class="col-md-6 ">
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          placeholder="Your Email"
                          required=""
                        />
                      </div>

                      <div class="col-md-12">
                        <input
                          type="text"
                          class="form-control"
                          name="subject"
                          placeholder="Subject"
                          required=""
                        />
                      </div>

                      <div class="col-md-12">
                        <textarea
                          class="form-control"
                          name="message"
                          rows="6"
                          placeholder="Message"
                          required=""
                        ></textarea>
                      </div>

                      <div class="col-md-12 text-center">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">
                          Your message has been sent. Thank you!
                        </div>

                        <button type="submit">Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer id="footer" class="footer dark-background">
          <div class="container footer-top">
            <div class="row gy-4">
              <div class="col-lg-4 col-md-6 footer-about">
                <a  class="logo d-flex align-items-center">
                  <span class=""><img className="img-fluid " width={"200px"} src='https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png'></img></span>
                </a>
                <div class="footer-contact pt-3">
                  <p>
                    P26R+524, Nazim-ud-din Rd, F-8 Markaz F 8 Markaz F-8,
                    Islamabad, Islamabad Capital Territory 44000
                  </p>
                  <p class="mt-3">
                    <strong>Phone:</strong> <span>+92 3000000000</span>
                  </p>
                  <p>
                    <strong>Email:</strong> <span>info@skans.pk</span>
                  </p>
                </div>
                <div class="social-links d-flex mt-4">
                  <a href="">
                    <i class="bi bi-twitter-x"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-2 col-md-3 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-2 col-md-3 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <a href="#">demo</a>
                  </li>
                  <li>
                    <a href="#">demo</a>
                  </li>
                  <li>
                    <a href="#"> demo</a>
                  </li>
                  <li>
                    <a href="#">demo</a>
                  </li>
                  <li>
                    <a href="#"> demo</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-12 footer-newsletter">
                <h4>Our Campuses</h4>

                {/* <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
      <form  method="post" class="php-email-form">
        <div class="newsletter-form"><input type="email" name="email"/><input type="submit" value="Subscribe"/></div>
        <div class="loading">Loading</div>
        <div class="error-message"></div>
        <div class="sent-message">Your subscription request has been sent. Thank you!</div>
      </form> */}
              </div>
            </div>
          </div>

          <div class="container copyright text-center mt-4">
            <p>
              © <span>Copyright</span>{" "}
              <strong class="px-1 sitename">SKANS</strong>{" "}
              <span>All Rights Reserved</span>
            </p>
            <div class="credits">
              <a href="skans.pk">SKANS</a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

const Header = () => {
  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
        <a href="index.html" className="logo d-flex align-items-center">
          {/* Uncomment the line below if you also wish to use an image logo */}
          {/* <img src="assets/img/logo.png" alt="Logo" /> */}
          <img
            width={"200px"}
            className="img-fluid"
            src="https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png"
          ></img>
        </a>
        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <a href="#hero" className="active">
                Home
              </a>
            </li>
            <li>
              <a href="#about">Life@SKANS</a>
            </li>
            <li>
              <a href="#features">High Achievers</a>
            </li>
            {/* <li className="dropdown">
              <a href="#">
                <span>Dropdown</span>{" "}
                <i className="bi bi-chevron-down toggle-dropdown"></i>
              </a>
              <ul>
                <li>
                  <a href="#">Dropdown 1</a>
                </li>
                <li className="dropdown">
                  <a href="#">
                    <span>Deep Dropdown</span>{" "}
                    <i className="bi bi-chevron-down toggle-dropdown"></i>
                  </a>
                  <ul>
                    <li>
                      <a href="#">Deep Dropdown 1</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 2</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 3</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 4</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 5</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Dropdown 2</a>
                </li>
                <li>
                  <a href="#">Dropdown 3</a>
                </li>
                <li>
                  <a href="#">Dropdown 4</a>
                </li>
              </ul>
            </li> */}
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
          <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>
      </div>
    </header>
  );
};

const HeroSection = () => {
  return (
    <section className="hero section dark-background">
      <img
        src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExdW1rbDlzZ3g4bGw4cXdycmU5YXcwb252djl4bTRkZ2p4d2IycXNvdyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/E9nnZZKRTUPpl73BXn/giphy.webp"
        alt="Hero Background"
        className="hero-bg"
      />
      <div className="container">
        <div className="row gy-4 justify-content-between">
          <div
            className="col-lg-4 order-lg-last hero-img"
            // data-aos="zoom-out"
            // data-aos-delay="100"
          >
            <img
              src="https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png"
              className="img-fluid animated pt-5"
              alt="Hero"
            />
          </div>
          <div
            className="col-lg-6 d-flex flex-column justify-content-center"
            data-aos="fade-in"
          >
            <h1>
              Welcome to <br></br>
              <span>SKANS SCHOOLS OF ACCOUNTANCY</span>
            </h1>
            <p>
              SKANS is the acronym of an institution, which stands for Skills,
              Knowledge and Application Nurtured through Student-managed
              participative learning approach.
            </p>
            <div className="d-flex">
              <a href="https://skans.pk/SchoolVirtualTour" className="btn-get-started">
                Visit Schools
              </a>
              {/* <a
                href="https://www.youtube.com/watch?v=Y7f98aduVJ8"
                className="glightbox btn-watch-video d-flex align-items-center"
              >
                <i className="bi bi-play-circle"></i>
                <span>Watch Video</span>
              </a> */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://skans.pk/CollegeVirtualTour" className="btn-get-started">
                Visit College
              </a>
            </div>
          </div>
        </div>
      </div>
      <svg
        className="hero-waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
      >
        <defs>
          <path
            id="wave-path"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          ></path>
        </defs>
        <g className="wave1">
          <use xlinkHref="#wave-path" x="50" y="3"></use>
        </g>
        <g className="wave2">
          <use xlinkHref="#wave-path" x="50" y="0"></use>
        </g>
        <g className="wave3">
          <use xlinkHref="#wave-path" x="50" y="9"></use>
        </g>
      </svg>
    </section>
  );
};

export default App;
